<template>
    <el-row v-loading="$waiting.is('loading')">
        <el-col :span="12" v-if="data">
            <InpaymentEvents :events="events" />
            <table class="ml-2 mt-4" style="width:100%">
                <tr>
                    <td class="font-bold">Klient</td>
                    <td><el-input type="text" v-model="data.clientName" size="medium" disabled /></td>
                    <td style="width: 150px" />
                </tr>
                <tr>
                    <td class="font-bold">Datum</td>
                    <td>
                        <DateInputField size="medium" :pre-selected-value="formatDate(data.bookingDate)" disabled />
                    </td>
                    <td />
                </tr>
                <tr>
                    <td class="font-bold">Kontoutdrag text</td>
                    <td><el-input type="text" v-model="data.text" disabled /></td>
                </tr>
                <tr>
                    <td class="font-bold">Belopp</td>
                    <td><el-input type="text" v-model="swedishFormattedAmount" disabled /></td>
                    <td style="display: flex; align-items: self-end; height: 40px;">
                        <TextBase />
                        <template size="small" weight="normal">{{ data.obTransaction.amountValue | swedishNumberFormat }} {{ data.obTransaction.amountCurrency }}</template>
                    </td>
                </tr>
                <tr>
                    <td v-if="!isBooked" colspan="3" style="padding-top: 50px;">
                        <div class="col-span-7 self-start">
                            <el-form class="flex" ref="form" :model="formData" style="height: 40px;" :rules="rules">
                                <el-form-item prop="series">
                                    <el-select ref="serieDropdown" v-model="formData.series" placeholder="Serie" filterable>
                                        <el-option v-for="series in seriesData" :key="series.prefix" :label="series.name + ' (' + series.prefix + ')'" :value="series.prefix" />
                                    </el-select>
                                </el-form-item>
                                <el-form-item prop="date" class="ml-2">
                                    <DateInputField ref="dateInputField" :pre-selected-value="formatDate(data.bookingDate)" disabled />
                                </el-form-item>
                                <el-form-item prop="comment" class="ml-2" style="width: 70%">
                                    <el-input placeholder="Beskrivning" v-model="formData.comment" />
                                </el-form-item>
                            </el-form>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="3">
                        <VouchersTable
                            ref="vtable"
                            v-if="!isBooked"
                            :voucher-rows="voucherRows"
                            :account-plan-items="accountPlanItems"
                            :write-comments="false"
                            :periodize="false"
                            :financial-years="financialYears"
                            :source-date="data.bookingDate"
                            @updateValues="updateVoucherRow"
                            @newItemCreation="createNewItemComponent"
                            @removeVoucherRow="removeVoucherRow"
                            @createVoucher="createVoucherFromTable"
                        />

                        <div v-if="data.voucher" style="margin-top: 50px;" class="mb-2 py-10 border-b card-tail">
                            <div class="flex mb-4 pb-3 text-sm border-b">
                                <p class="w-[100px]">
                                    {{ data.voucher.number }}
                                </p>
                                <p class="w-[200px]">
                                    {{ formatDate(data.voucher.date) }}
                                </p>
                                <p class="w-[200px]">
                                    {{ formatDate(data.voucher.registerDate) }}
                                </p>
                                <p class="flex-1 wra">
                                    {{ data.voucher.text }}
                                </p>
                            </div>

                            <div class="flex bg-white sticky top-[102px] z-0">
                                <p class="font-semibold rounded w-[350px]">Konto</p>
                                <p class="font-semibold rounded w-[100px] text-right">Debet</p>
                                <p class="font-semibold rounded w-[100px] text-right mr-5">Kredit</p>
                                <p class="font-semibold rounded flex-1">Text</p>
                            </div>

                            <div v-for="item in data.voucher.transactions" :key="item.transactionId" class="flex text-sm">
                                <p class="w-[45px]">{{ item.account }}</p>
                                <p class="w-[305px]">{{ item.accountName }}</p>
                                <p class="w-[100px] text-right">
                                    {{ item.debit | swedishNumberFormat }}
                                </p>
                                <p class="w-[100px] text-right mr-2">
                                    {{ item.credit | swedishNumberFormat }}
                                </p>
                                <p class="w-[18px]">
                                    <span v-if="item.correction" class="flex items-center justify-center bg-company-blue w-4 h-4 mt-[2px] text-xs rounded-full text-white">K</span>
                                </p>
                                <p class="flex-1">
                                    {{ item.text }}
                                </p>
                            </div>
                        </div>
                    </td>
                    <td />
                </tr>
            </table>
        </el-col>

        <el-col :span="12" style="position: sticky; top: 15px;">
            <ImageViewer :attatchments="attatchments" />
            <div style="margin-top: 15px;">
                <el-button v-if="!isBooked" size="mini" type="primary" :disabled="cantCreateVoucher" @click="createVoucherFromTable">Registrera</el-button>
                <el-button v-if="(!isBooked && status == 2) || status == 3" size="mini" type="warning" @click="supplementModal = true">Begär Komplettering</el-button>
                <el-button size="mini" type="primary" @click="goToNextInpayment">Nästa</el-button>
                <el-button size="mini" type="primary" @click="goToInpaymentList">Tillbaka</el-button>
            </div>
        </el-col>
        <InpaymentDialogs :supplement-modal="supplementModal" @supplement="supplementInpayment" @closeSupplementModal="supplementModal = false" :close-on-click-modal="true" />
    </el-row>
</template>

<script>
import TextBase from "@/components/content/Text.vue";
import moment from "moment";
import { mapState } from "vuex";
import Api from "./inpayments.api.js";
import queryString from "query-string";
export default {
    data: function() {
        return {
            attatchments: [],
            events: [],
            financialYears: [],
            isBooked: false,
            voucherRows: [],
            data: undefined,
            contentUrl: undefined,
            imgType: 2,
            status: undefined,
            supplementModal: false,
            formData: {
                series: "",
                comment: "",
            },
            rules: {
                series: [{ validator: this.validateNonEmpty, trigger: "blur" }],
                comment: [{ validator: this.validateNonEmpty, trigger: "blur" }],
            },
        };
    },
    components: {
        TextBase,
        DateInputField: () => import(/* webpackChunkName: "DateInputField" */ "@/components/DateInputField.vue"),
        VouchersTable: () => import(/* webpackChunkName: "VouchersTable" */ "@/components/vouchersTable/v1/VouchersTable.vue"),
        InpaymentEvents: () => import(/* webpackChunkName: "InpaymentEvents" */ "./components/InpaymentEvents.vue"),
        ImageViewer: () => import(/* webpackChunkName: "ImageViewer" */ "@/components/ImageViewer.vue"),
        InpaymentDialogs: () => import(/* webpackChunkName: "InpaymentDialogs" */ "./InpaymentDialogs.vue"),
    },
    async created() {
        this.$waiting.start("loading");
        this.getSeries();
        this.obTransactionId = this.$route.params.obTransactionId;
        this.data = await Api.getById(this.obTransactionId);
        this.status = this.data.status;
        this.events = await Api.GetEvents(this.obTransactionId);
        if (this.data.voucherId != null) this.isBooked = true;
        this.formData.comment = this.data.text;
        this.financialYears = await Api.getOpenFinancialYears(this.data.clientId);
        this.attatchments = await Api.getDocuments(this.obTransactionId);
        if (this.attatchments.length > 0 && this.attatchments[0].type == "image") {
            this.imgType = 2;
            this.contentUrl = "data:image/png;base64," + this.attatchments[0].data;
        } else if (this.attatchments.length > 0) {
            this.imgType = 1;
            this.contentUrl = this.attatchments[0].url;
        }
        this.createNewItemComponent();
        this.$waiting.end("loading");
    },
    methods: {
        validateNonEmpty(rule, value, callback) {
            if (!value) {
                callback(new Error("Required"));
            } else {
                callback();
            }
        },
        statusToUpperCase(status) {
            switch (status) {
                case "inquired":
                    return "Inquired";
                case "draft":
                    return "Draft";
                case "uploaded":
                    return "Uploaded";
                case "beSupplemented":
                    return "Besupplemented";
                case "adminhandled":
                    return "Adminhandled";
                default:
                    return "";
            }
        },
        async supplementInpayment(comment) {
            this.supplementModal = false;
            await Api.supplementInpayment(this.obTransactionId, comment);
            this.$notify.success({ title: "Kompletterings förfrågan skickad" });
            this.goToInpaymentList();
        },
        formatDate(date) {
            return date
                ? moment
                      .utc(date)
                      .local()
                      .format("YYYY-MM-DD")
                : "";
        },
        async getSeries() {
            this.seriesData = await Api.getSeries();
            this.$refs.serieDropdown.focus();
        },
        goToInpaymentList() {
            const currentQuery = window.location.search;
            const newURL = `/inpayments${currentQuery}`;
            window.location.href = newURL;
        },
        async goToNextInpayment() {
            const { search, status, dateFrom, dateTo, clientId } = queryString.parse(window.location.search, { sort: false });
            this.status = this.statusToUpperCase(status);
            const nextobTransactionId = await Api.getNextInpayment(this.obTransactionId, search, this.status, dateFrom, dateTo, clientId);
            if (nextobTransactionId != -1) {
                const currentQuery = window.location.search;
                const newURL = `/inpayments/${nextobTransactionId}${currentQuery}`;
                window.location.href = newURL;
            } else {
                this.goToInpaymentList();
            }
        },
        updateVoucherRow({ index, account, debit, credit, comment, startDate, endDate }) {
            this.voucherRows[index].account = account;
            this.voucherRows[index].debit = debit;
            this.voucherRows[index].credit = credit;
            this.voucherRows[index].text = comment;
            this.voucherRows[index].startDate = startDate;
            this.voucherRows[index].endDate = endDate;
        },
        createNewItemComponent() {
            if (this.voucherRows.length === 0) {
                this.voucherRows.push({
                    id: this.voucherRows.length,
                    account: 1930,
                    debit: this.data.amount,
                    credit: 0,
                    text: "",
                    readOnly: true,
                    startDate: null,
                    endDate: null,
                    predefined: false,
                });
            }
            this.voucherRows.push({
                id: this.voucherRows.length,
                account: null,
                debit: 0,
                credit: 0,
                text: "",
                readOnly: false,
                startDate: null,
                endDate: null,
                predefined: false,
            });
        },
        removeVoucherRow(index) {
            const lastRow = index === this.voucherRows.length - 1;
            this.voucherRows = this.voucherRows.splice(index, 1);

            if (this.voucherRows.filter(item => !item.readOnly).length === 0 || lastRow) {
                this.voucherRows.push({
                    id: this.voucherRows.length,
                    account: null,
                    debit: 0,
                    credit: 0,
                    text: "",
                    readOnly: false,
                    startDate: null,
                    endDate: null,
                });
            }
        },
        async createVoucherFromTable() {
            if (!this.cantCreateVoucher) {
                const confirm = await this.$dialog.title("Bekräftelse").confirm("Är du säker?");
                if (!confirm) return;
                this.bookInpayment();
                console.log("Betalning bokförd!");
            }
        },
        async bookInpayment() {
            await Api.bookInpayment(this.data.bookingDate, this.formData.series, this.formData.comment, this.voucherRows, this.data.clientId, this.obTransactionId);
            this.goToInpaymentList();
        },
    },
    computed: {
        ...mapState({
            accountsInfo: state => state.accountPlans,
        }),
        accountPlanItems() {
            if (this.data.clientCompanyType == "Aktiebolag") return this.stockCompanyAccountPlanItems;
            else return this.privateFirmAccountPlanItems;
        },
        stockCompanyAccountPlanItems() {
            var stockAccountPlans = this.accountsInfo.filter(a => a.companyType == 2)[0].accounts;
            return stockAccountPlans.map(item => {
                return {
                    name: item.name,
                    value: item.value,
                    accruable: item.accruable,
                    assetAccount: item.assetAccount,
                    debtAccount: item.debtAccount,
                };
            });
        },
        swedishFormattedAmount() {
            const swedishFormattedAmount = this.$options.filters.swedishNumberFormat(this.data.amount);
            return swedishFormattedAmount;
        },
        privateFirmAccountPlanItems() {
            var privateFirmAccountPlans = this.accountsInfo.filter(a => a.companyType == 1)[0].accounts;
            return privateFirmAccountPlans.map(item => {
                return {
                    name: item.name,
                    value: item.value,
                    accruable: item.accruable,
                    assetAccount: item.assetAccount,
                    debtAccount: item.debtAccount,
                };
            });
        },
        debitSummary() {
            const summary = this.voucherRows.reduce((total, amount) => {
                const result = total + amount.debit;
                return result.roundToEven2();
            }, 0);
            return summary;
        },

        creditSummary() {
            const summary = this.voucherRows.reduce((total, amount) => {
                const result = total + amount.credit;
                return result.roundToEven2();
            }, 0);
            return summary;
        },
        cantCreateVoucher() {
            return this.debitSummary === 0 || this.creditSummary === 0 || this.debitSummary !== this.creditSummary || !this.formData.series || !this.formData.comment;
        },
        bookAccounts() {
            if (this.mlResult.bookAccount1 == null) return "";
            return this.mlResult.bookAccount1 + ", " + this.mlResult.bookAccount2 + ", " + this.mlResult.bookAccount3;
        },
        getCapitalizedStatus() {
            return this.capitalizeFirstLetter(this.status);
        },
    },
};
</script>

<style scoped>
.el-input__prefix span {
    position: absolute;
    top: 9px;
    left: 3px;
}
table tr td {
    padding: 6px;
    font-size: 14px;
}
table td {
    border: 0px !important;
}
</style>
